import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Minus, Upload, Link as LinkIcon } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { parseRecipeFromUrl, saveRecipe } from '../lib/recipeParser';
import { useAuth } from '../contexts/AuthContext';

export default function AddRecipe() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [ingredients, setIngredients] = React.useState([{ item: '', amount: '' }]);
  const [instructions, setInstructions] = React.useState(['']);
  const [recipeUrl, setRecipeUrl] = React.useState('');
  const [urlError, setUrlError] = React.useState('');

  const handleImportRecipe = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setUrlError('');

    try {
      const parsedRecipe = await parseRecipeFromUrl(recipeUrl);
      await saveRecipe(parsedRecipe);
      navigate('/recipes');
    } catch (error) {
      console.error('Error importing recipe:', error);
      setUrlError(error instanceof Error ? error.message : 'Failed to import recipe. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const recipe = {
      title: formData.get('title'),
      description: formData.get('description'),
      prep_time: parseInt(formData.get('prep_time') as string),
      cook_time: parseInt(formData.get('cook_time') as string),
      servings: parseInt(formData.get('servings') as string),
      image_url: formData.get('image_url'),
      ingredients: ingredients.filter(ing => ing.item && ing.amount),
      instructions: instructions.filter(inst => inst),
      user_id: user?.id,
      author_name: user?.user_metadata?.full_name || 'Anonymous'
    };

    try {
      const { data, error } = await supabase
        .from('recipes')
        .insert([recipe])
        .select('id')
        .single();

      if (error) throw error;
      navigate(`/recipes/${data.id}`);
    } catch (error) {
      console.error('Error adding recipe:', error);
      alert('Error adding recipe. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-4xl font-serif text-[#2E5A43] mb-8">Add New Recipe</h1>

        {/* Recipe URL Import Form */}
        <div className="mb-12 p-6 bg-gray-50 rounded-lg">
          <h2 className="text-2xl font-serif text-[#2E5A43] mb-4">Import Recipe from URL</h2>
          <form onSubmit={handleImportRecipe} className="space-y-4">
            <div>
              <label htmlFor="recipe_url" className="block text-sm font-medium text-gray-700">
                Recipe URL
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <LinkIcon className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="url"
                    id="recipe_url"
                    value={recipeUrl}
                    onChange={(e) => setRecipeUrl(e.target.value)}
                    className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
                    placeholder="https://example.com/recipe"
                  />
                </div>
                <button
                  type="submit"
                  disabled={loading || !recipeUrl}
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2E5A43] hover:bg-[#234735] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2E5A43] disabled:opacity-50"
                >
                  {loading ? 'Importing...' : 'Import'}
                </button>
              </div>
              {urlError && (
                <p className="mt-2 text-sm text-red-600">{urlError}</p>
              )}
            </div>
          </form>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-3 bg-white text-lg font-medium text-gray-900">Or add recipe manually</span>
          </div>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-6 mt-12">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Recipe Title</label>
            <input
              type="text"
              name="title"
              id="title"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              id="description"
              rows={3}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="prep_time" className="block text-sm font-medium text-gray-700">Prep Time (mins)</label>
              <input
                type="number"
                name="prep_time"
                id="prep_time"
                required
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
              />
            </div>
            <div>
              <label htmlFor="cook_time" className="block text-sm font-medium text-gray-700">Cook Time (mins)</label>
              <input
                type="number"
                name="cook_time"
                id="cook_time"
                required
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
              />
            </div>
            <div>
              <label htmlFor="servings" className="block text-sm font-medium text-gray-700">Servings</label>
              <input
                type="number"
                name="servings"
                id="servings"
                required
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
              />
            </div>
          </div>

          <div>
            <label htmlFor="image_url" className="block text-sm font-medium text-gray-700">Image URL</label>
            <input
              type="url"
              name="image_url"
              id="image_url"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Ingredients</label>
            {ingredients.map((ing, index) => (
              <div key={index} className="flex gap-4 mb-2">
                <input
                  type="text"
                  value={ing.amount}
                  onChange={(e) => {
                    const newIngredients = [...ingredients];
                    newIngredients[index].amount = e.target.value;
                    setIngredients(newIngredients);
                  }}
                  placeholder="Amount"
                  className="w-1/2 rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
                />
                <input
                  type="text"
                  value={ing.item}
                  onChange={(e) => {
                    const newIngredients = [...ingredients];
                    newIngredients[index].item = e.target.value;
                    setIngredients(newIngredients);
                  }}
                  placeholder="Ingredient"
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
                />
                <button
                  type="button"
                  onClick={() => {
                    const newIngredients = ingredients.filter((_, i) => i !== index);
                    setIngredients(newIngredients);
                  }}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <Minus className="h-5 w-5" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => setIngredients([...ingredients, { item: '', amount: '' }])}
              className="mt-2 flex items-center text-[#2E5A43] hover:text-[#234735]"
            >
              <Plus className="h-5 w-5 mr-1" />
              Add Ingredient
            </button>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Instructions</label>
            {instructions.map((instruction, index) => (
              <div key={index} className="flex gap-4 mb-2">
                <span className="w-8 text-right pt-2">{index + 1}.</span>
                <textarea
                  value={instruction}
                  onChange={(e) => {
                    const newInstructions = [...instructions];
                    newInstructions[index] = e.target.value;
                    setInstructions(newInstructions);
                  }}
                  rows={2}
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-[#2E5A43] focus:ring-[#2E5A43]"
                />
                <button
                  type="button"
                  onClick={() => {
                    const newInstructions = instructions.filter((_, i) => i !== index);
                    setInstructions(newInstructions);
                  }}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <Minus className="h-5 w-5" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => setInstructions([...instructions, ''])}
              className="mt-2 flex items-center text-[#2E5A43] hover:text-[#234735]"
            >
              <Plus className="h-5 w-5 mr-1" />
              Add Step
            </button>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="bg-[#2E5A43] text-white px-6 py-2 rounded-md hover:bg-[#234735] disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Recipe'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}