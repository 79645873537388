import { supabase } from './supabase';

interface ParsedRecipe {
  title: string;
  description: string;
  ingredients: { item: string; amount: string }[];
  instructions: string[];
  prep_time?: number;
  cook_time?: number;
  servings?: number;
  image_url?: string;
}

// Default recipe image if none is provided or if the provided URL is invalid
const DEFAULT_RECIPE_IMAGE = "https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?ixlib=rb-4.0.3&auto=format&fit=crop&w=1470&q=80";

async function isImageUrlValid(url: string): Promise<boolean> {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    const contentType = response.headers.get('content-type');
    return response.ok && contentType?.startsWith('image/');
  } catch {
    return false;
  }
}

async function getRecipeImage(title: string, description: string, ingredients: { item: string; amount: string }[]): Promise<string> {
  try {
    // Create a detailed description for the image search
    const mainIngredients = ingredients
      .slice(0, 3)
      .map(ing => ing.item)
      .join(', ');

    const prompt = `Find a unique, high-quality food photography image URL from Unsplash for this specific recipe:
    Title: ${title}
    Description: ${description}
    Main Ingredients: ${mainIngredients}
    
    Requirements:
    - Must be a direct Unsplash image URL (https://images.unsplash.com/...)
    - Must show this EXACT dish or something VERY similar
    - Must be a professional food photography shot
    - Must be well-lit and appetizing
    - Must be in landscape orientation
    - Must be high resolution
    - Must be a UNIQUE image that stands out
    - Must focus on the specific ingredients and style of this recipe
    - Must NOT be a generic food photo
    
    Return ONLY the Unsplash image URL, nothing else.`;

    const response = await fetch('https://api.deepseek.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_DEEPSEEK_API_KEY}`,
      },
      body: JSON.stringify({
        model: 'deepseek-chat',
        messages: [{ role: 'user', content: prompt }],
        temperature: 0.7, // Increased for more variety
        max_tokens: 100
      })
    });

    if (!response.ok) {
      console.error('Failed to get image from DeepSeek');
      return DEFAULT_RECIPE_IMAGE;
    }

    const data = await response.json();
    const imageUrl = data.choices[0].message.content.trim();

    // Validate that it's a proper Unsplash URL
    if (!imageUrl.startsWith('https://images.unsplash.com/')) {
      console.error('Invalid Unsplash URL:', imageUrl);
      return DEFAULT_RECIPE_IMAGE;
    }

    // Validate the URL is accessible
    if (await isImageUrlValid(imageUrl)) {
      return imageUrl;
    }

    // If the first attempt fails, try again with a more generic search
    const fallbackPrompt = `Find a unique food photography image URL from Unsplash that represents:
    Dish Type: ${title}
    Style: Professional food photography, appetizing presentation
    Must be a direct Unsplash URL and different from commonly used images.
    Return ONLY the URL.`;

    const fallbackResponse = await fetch('https://api.deepseek.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_DEEPSEEK_API_KEY}`,
      },
      body: JSON.stringify({
        model: 'deepseek-chat',
        messages: [{ role: 'user', content: fallbackPrompt }],
        temperature: 0.9, // Even higher for more variety
        max_tokens: 100
      })
    });

    if (fallbackResponse.ok) {
      const fallbackData = await fallbackResponse.json();
      const fallbackImageUrl = fallbackData.choices[0].message.content.trim();
      
      if (fallbackImageUrl.startsWith('https://images.unsplash.com/') && 
          await isImageUrlValid(fallbackImageUrl)) {
        return fallbackImageUrl;
      }
    }

    return DEFAULT_RECIPE_IMAGE;
  } catch (error) {
    console.error('Error getting recipe image:', error);
    return DEFAULT_RECIPE_IMAGE;
  }
}

function cleanJsonString(str: string): string {
  // Remove markdown code blocks
  str = str.replace(/```json\n/g, '').replace(/```/g, '');
  // Remove any leading/trailing whitespace
  str = str.trim();
  return str;
}

export async function parseRecipeFromUrl(url: string): Promise<ParsedRecipe> {
  try {
    if (!url) {
      throw new Error('Recipe URL is required');
    }

    // Validate URL format
    try {
      new URL(url);
    } catch {
      throw new Error('Please enter a valid URL. Example: https://example.com/recipe');
    }

    const prompt = `Please analyze this recipe URL: ${url}
    Extract and return ONLY a JSON object with the following structure:
    {
      "title": "Recipe title",
      "description": "Brief description",
      "ingredients": [{"item": "ingredient name", "amount": "amount with unit"}],
      "instructions": ["Step 1", "Step 2", ...],
      "prepTime": number of minutes,
      "cookTime": number of minutes,
      "servings": number of servings
    }
    IMPORTANT: Return ONLY the JSON object, no markdown formatting or code blocks.`;

    // Add a timeout to the fetch request
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // 30-second timeout

    try {
      console.log('Making request to DeepSeek API...');
      const response = await fetch('https://api.deepseek.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${import.meta.env.VITE_DEEPSEEK_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'deepseek-chat',
          messages: [
            {
              role: 'user',
              content: prompt
            }
          ],
          temperature: 0.1,
          max_tokens: 2000
        }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('DeepSeek API error response:', errorText);
        let errorData;
        
        try {
          errorData = JSON.parse(errorText);
        } catch {
          throw new Error('Network error. Please check your connection and try again.');
        }

        if (response.status === 402) {
          throw new Error('Recipe import is temporarily unavailable. Please add the recipe manually.');
        }

        throw new Error(errorData?.error?.message || 'Failed to import recipe. Please try again.');
      }

      const chatResponse = await response.json();
      console.log('DeepSeek API response:', chatResponse);
      
      if (!chatResponse.choices?.[0]?.message?.content) {
        throw new Error('Invalid response from the recipe parser. Please add the recipe manually.');
      }

      let data;
      try {
        const content = chatResponse.choices[0].message.content;
        console.log('Raw content:', content);
        const cleanContent = cleanJsonString(content);
        console.log('Cleaned content:', cleanContent);
        data = JSON.parse(cleanContent);
      } catch (error) {
        console.error('JSON parse error:', error);
        throw new Error('Failed to parse recipe data. Please add the recipe manually.');
      }

      if (!data || typeof data !== 'object') {
        console.error('Invalid data format:', data);
        throw new Error('Invalid recipe format. Please add the recipe manually.');
      }

      if (!data.title) {
        console.error('Missing required title:', data);
        throw new Error('Recipe title is required. Please add the recipe manually.');
      }

      const ingredients = Array.isArray(data.ingredients) 
        ? data.ingredients.map((ing: any) => ({
            item: typeof ing === 'string' ? ing : ing?.item || ing?.name || 'Unknown ingredient',
            amount: typeof ing === 'string' ? '' : ing?.amount || ''
          }))
        : [];

      // Get a relevant image for the recipe
      const imageUrl = await getRecipeImage(data.title, data.description, ingredients);

      return {
        title: data.title,
        description: data.description || '',
        ingredients,
        instructions: Array.isArray(data.instructions)
          ? data.instructions.map((inst: any) => String(inst))
          : [],
        prep_time: Number(data.prepTime) || 0,
        cook_time: Number(data.cookTime) || 0,
        servings: Number(data.servings) || 2,
        image_url: imageUrl
      };
    } finally {
      clearTimeout(timeoutId);
    }
  } catch (error) {
    console.error('Error parsing recipe:', error);
    throw error instanceof Error 
      ? error 
      : new Error('Failed to parse recipe. Please try adding the recipe manually.');
  }
}

export async function saveRecipe(recipe: ParsedRecipe): Promise<string> {
  const { data: { user }, error: authError } = await supabase.auth.getUser();
  
  if (authError || !user) {
    throw new Error('Please sign in to save recipes');
  }

  if (!recipe.title || !recipe.ingredients || !recipe.instructions) {
    throw new Error('Invalid recipe data. Please check all required fields.');
  }

  const { data, error: insertError } = await supabase
    .from('recipes')
    .insert([{
      ...recipe,
      user_id: user.id
    }])
    .select('id')
    .single();

  if (insertError) {
    throw new Error('Failed to save recipe. Please try again.');
  }

  return data.id;
}