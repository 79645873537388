import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Clock, Users, Trash2, Edit2, Save, X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';

interface Recipe {
  id: string;
  title: string;
  description: string;
  ingredients: { item: string; amount: string }[];
  instructions: string[];
  prep_time: number;
  cook_time: number;
  servings: number;
  image_url: string;
  user_id: string;
  notes?: string;
}

export default function RecipeDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [recipe, setRecipe] = React.useState<Recipe | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [deleting, setDeleting] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [editedRecipe, setEditedRecipe] = React.useState<Recipe | null>(null);

  React.useEffect(() => {
    async function fetchRecipe() {
      try {
        const { data, error } = await supabase
          .from('recipes')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        setRecipe(data);
        setEditedRecipe(data);
      } catch (error) {
        console.error('Error fetching recipe:', error);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchRecipe();
    }
  }, [id]);

  const handleDelete = async () => {
    if (!recipe || !user) return;
    
    if (!window.confirm('Are you sure you want to delete this recipe? This action cannot be undone.')) {
      return;
    }

    setDeleting(true);
    try {
      const { error } = await supabase
        .from('recipes')
        .delete()
        .eq('id', recipe.id)
        .eq('user_id', user.id);

      if (error) throw error;
      navigate('/recipes');
    } catch (error) {
      console.error('Error deleting recipe:', error);
      alert('Failed to delete recipe. Please try again.');
    } finally {
      setDeleting(false);
    }
  };

  const handleSave = async () => {
    if (!editedRecipe || !user) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('recipes')
        .update({
          title: editedRecipe.title,
          description: editedRecipe.description,
          ingredients: editedRecipe.ingredients,
          instructions: editedRecipe.instructions,
          prep_time: editedRecipe.prep_time,
          cook_time: editedRecipe.cook_time,
          servings: editedRecipe.servings,
          notes: editedRecipe.notes
        })
        .eq('id', editedRecipe.id)
        .eq('user_id', user.id);

      if (error) throw error;
      setRecipe(editedRecipe);
      setEditing(false);
    } catch (error) {
      console.error('Error updating recipe:', error);
      alert('Failed to update recipe. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setEditedRecipe(recipe);
    setEditing(false);
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">Loading recipe...</div>
      </div>
    );
  }

  if (!recipe || !editedRecipe) {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">Recipe not found</div>
      </div>
    );
  }

  const canEdit = user && recipe.user_id === user.id;
  const displayRecipe = editing ? editedRecipe : recipe;

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="h-96 relative">
          <img
            src={recipe.image_url || 'https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?ixlib=rb-4.0.3'}
            alt={recipe.title}
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="p-8">
          <div className="flex justify-between items-start mb-4">
            {editing ? (
              <input
                type="text"
                value={editedRecipe.title}
                onChange={(e) => setEditedRecipe({ ...editedRecipe, title: e.target.value })}
                className="text-4xl font-serif text-[#2E5A43] bg-gray-50 border border-gray-300 rounded px-2 py-1 w-full"
              />
            ) : (
              <h1 className="text-4xl font-serif text-[#2E5A43]">{recipe.title}</h1>
            )}
            {canEdit && (
              <div className="flex space-x-2">
                {editing ? (
                  <>
                    <button
                      onClick={handleSave}
                      disabled={saving}
                      className="flex items-center px-4 py-2 text-[#2E5A43] hover:text-[#234735] disabled:opacity-50"
                      title="Save changes"
                    >
                      <Save className="h-5 w-5" />
                    </button>
                    <button
                      onClick={handleCancel}
                      className="flex items-center px-4 py-2 text-gray-600 hover:text-gray-800"
                      title="Cancel editing"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setEditing(true)}
                      className="flex items-center px-4 py-2 text-[#2E5A43] hover:text-[#234735]"
                      title="Edit recipe"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={handleDelete}
                      disabled={deleting}
                      className="flex items-center px-4 py-2 text-red-600 hover:text-red-800 disabled:opacity-50"
                      title="Delete recipe"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
          
          {editing ? (
            <textarea
              value={editedRecipe.description}
              onChange={(e) => setEditedRecipe({ ...editedRecipe, description: e.target.value })}
              className="w-full h-24 bg-gray-50 border border-gray-300 rounded px-3 py-2 mb-6"
            />
          ) : (
            <p className="text-gray-600 mb-6">{recipe.description}</p>
          )}
          
          <div className="flex items-center space-x-6 mb-8 text-gray-500">
            <div className="flex items-center">
              <Clock className="h-5 w-5 mr-2" />
              <div>
                {editing ? (
                  <>
                    <input
                      type="number"
                      value={editedRecipe.prep_time}
                      onChange={(e) => setEditedRecipe({ ...editedRecipe, prep_time: parseInt(e.target.value) })}
                      className="w-20 bg-gray-50 border border-gray-300 rounded px-2 py-1"
                    />
                    <span className="ml-1">mins prep</span>
                    <input
                      type="number"
                      value={editedRecipe.cook_time}
                      onChange={(e) => setEditedRecipe({ ...editedRecipe, cook_time: parseInt(e.target.value) })}
                      className="w-20 bg-gray-50 border border-gray-300 rounded px-2 py-1 ml-2"
                    />
                    <span className="ml-1">mins cook</span>
                  </>
                ) : (
                  <>
                    <p className="text-sm">Prep: {displayRecipe.prep_time} mins</p>
                    <p className="text-sm">Cook: {displayRecipe.cook_time} mins</p>
                  </>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <Users className="h-5 w-5 mr-2" />
              {editing ? (
                <input
                  type="number"
                  value={editedRecipe.servings}
                  onChange={(e) => setEditedRecipe({ ...editedRecipe, servings: parseInt(e.target.value) })}
                  className="w-20 bg-gray-50 border border-gray-300 rounded px-2 py-1"
                />
              ) : (
                <p className="text-sm">Serves {displayRecipe.servings}</p>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-2xl font-serif text-[#2E5A43] mb-4">Ingredients</h2>
              {editing ? (
                <div className="space-y-2">
                  {editedRecipe.ingredients.map((ingredient, index) => (
                    <div key={index} className="flex space-x-2">
                      <input
                        type="text"
                        value={ingredient.amount}
                        onChange={(e) => {
                          const newIngredients = [...editedRecipe.ingredients];
                          newIngredients[index] = { ...ingredient, amount: e.target.value };
                          setEditedRecipe({ ...editedRecipe, ingredients: newIngredients });
                        }}
                        className="w-24 bg-gray-50 border border-gray-300 rounded px-2 py-1"
                        placeholder="Amount"
                      />
                      <input
                        type="text"
                        value={ingredient.item}
                        onChange={(e) => {
                          const newIngredients = [...editedRecipe.ingredients];
                          newIngredients[index] = { ...ingredient, item: e.target.value };
                          setEditedRecipe({ ...editedRecipe, ingredients: newIngredients });
                        }}
                        className="flex-1 bg-gray-50 border border-gray-300 rounded px-2 py-1"
                        placeholder="Ingredient"
                      />
                      <button
                        onClick={() => {
                          const newIngredients = editedRecipe.ingredients.filter((_, i) => i !== index);
                          setEditedRecipe({ ...editedRecipe, ingredients: newIngredients });
                        }}
                        className="text-red-600 hover:text-red-800"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => {
                      setEditedRecipe({
                        ...editedRecipe,
                        ingredients: [...editedRecipe.ingredients, { item: '', amount: '' }]
                      });
                    }}
                    className="text-[#2E5A43] hover:text-[#234735] font-medium"
                  >
                    + Add Ingredient
                  </button>
                </div>
              ) : (
                <ul className="space-y-2">
                  {displayRecipe.ingredients.map((ingredient, index) => (
                    <li key={index} className="flex items-center">
                      <span className="w-24 text-gray-600">{ingredient.amount}</span>
                      <span>{ingredient.item}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            
            <div>
              <h2 className="text-2xl font-serif text-[#2E5A43] mb-4">Instructions</h2>
              {editing ? (
                <div className="space-y-2">
                  {editedRecipe.instructions.map((instruction, index) => (
                    <div key={index} className="flex space-x-2">
                      <span className="w-6 text-gray-500">{index + 1}.</span>
                      <textarea
                        value={instruction}
                        onChange={(e) => {
                          const newInstructions = [...editedRecipe.instructions];
                          newInstructions[index] = e.target.value;
                          setEditedRecipe({ ...editedRecipe, instructions: newInstructions });
                        }}
                        className="flex-1 bg-gray-50 border border-gray-300 rounded px-2 py-1"
                        rows={2}
                      />
                      <button
                        onClick={() => {
                          const newInstructions = editedRecipe.instructions.filter((_, i) => i !== index);
                          setEditedRecipe({ ...editedRecipe, instructions: newInstructions });
                        }}
                        className="text-red-600 hover:text-red-800"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => {
                      setEditedRecipe({
                        ...editedRecipe,
                        instructions: [...editedRecipe.instructions, '']
                      });
                    }}
                    className="text-[#2E5A43] hover:text-[#234735] font-medium"
                  >
                    + Add Step
                  </button>
                </div>
              ) : (
                <ol className="space-y-4">
                  {displayRecipe.instructions.map((instruction, index) => (
                    <li key={index} className="flex">
                      <span className="font-bold mr-4">{index + 1}.</span>
                      <span>{instruction}</span>
                    </li>
                  ))}
                </ol>
              )}
            </div>
          </div>

          <div className="mt-8">
            <h2 className="text-2xl font-serif text-[#2E5A43] mb-4">Notes</h2>
            {editing ? (
              <textarea
                value={editedRecipe.notes || ''}
                onChange={(e) => setEditedRecipe({ ...editedRecipe, notes: e.target.value })}
                className="w-full h-32 bg-gray-50 border border-gray-300 rounded px-3 py-2"
                placeholder="Add your notes here..."
              />
            ) : (
              <p className="text-gray-600">
                {displayRecipe.notes || 'No notes added yet.'}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}