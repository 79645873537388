import React from 'react';
import { UtensilsCrossed, Heart, Users } from 'lucide-react';

export default function About() {
  return (
    <div className="bg-[#FAF7F2]">
      {/* Hero Section */}
      <div className="relative h-[400px]">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1556910103-1c02745aae4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80")',
            filter: 'brightness(0.5)'
          }}
        />
        <div className="relative z-10 h-full flex items-center justify-center">
          <h1 className="text-5xl font-serif text-white text-center">Our Story</h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
          <h2 className="text-3xl font-serif text-[#2E5A43] mb-6">The Stanley Family Legacy</h2>
          <p className="text-gray-700 mb-6 leading-relaxed">
            For three generations, the Stanley family has been at the heart of culinary excellence. What started as a humble collection of family recipes has blossomed into Stan & Pan, a celebration of global flavors and cherished cooking traditions.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed">
            Our journey began in our grandmother's kitchen, where the aroma of freshly baked bread and simmering stews filled the air. Those early lessons in love, patience, and the art of cooking have shaped our approach to food and family to this day.
          </p>
        </div>

        {/* Values Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="text-center">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <UtensilsCrossed className="h-12 w-12 text-[#2E5A43] mx-auto mb-4" />
              <h3 className="text-xl font-serif text-gray-900 mb-2">Culinary Excellence</h3>
              <p className="text-gray-600">Every recipe is crafted with care and tested to perfection</p>
            </div>
          </div>
          <div className="text-center">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Heart className="h-12 w-12 text-[#2E5A43] mx-auto mb-4" />
              <h3 className="text-xl font-serif text-gray-900 mb-2">Passion for Food</h3>
              <p className="text-gray-600">We believe great food brings people together</p>
            </div>
          </div>
          <div className="text-center">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Users className="h-12 w-12 text-[#2E5A43] mx-auto mb-4" />
              <h3 className="text-xl font-serif text-gray-900 mb-2">Family Tradition</h3>
              <p className="text-gray-600">Three generations of cooking expertise</p>
            </div>
          </div>
        </div>

        {/* Mission Statement */}
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-serif text-[#2E5A43] mb-6">Our Mission</h2>
          <p className="text-gray-700 mb-6 leading-relaxed">
            At Stan & Pan, we're dedicated to sharing our passion for exceptional cooking with food lovers everywhere. Our carefully curated collection of recipes spans continents and cultures, each dish telling its own unique story.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Whether you're an experienced chef or just starting your culinary journey, our detailed recipes and cooking tips will help you create memorable meals for your loved ones. We believe that good food has the power to bring people together and create lasting memories.
          </p>
        </div>
      </div>
    </div>
  );
}