import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UtensilsCrossed, UserCircle, ChefHat, Info, LogOut } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';

export default function Navbar() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate('/');
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <ChefHat className="h-8 w-8 text-[#2E5A43]" />
              <span className="font-serif text-xl text-[#2E5A43]">Stan & Pan</span>
            </Link>
          </div>
          
          <div className="flex items-center space-x-6">
            <Link to="/about" className="flex items-center space-x-1 text-gray-700 hover:text-[#2E5A43]">
              <Info className="h-5 w-5" />
              <span>About</span>
            </Link>
            <Link to="/recipes" className="flex items-center space-x-1 text-gray-700 hover:text-[#2E5A43]">
              <UtensilsCrossed className="h-5 w-5" />
              <span>Recipes</span>
            </Link>
            
            {user ? (
              <div className="flex items-center space-x-4">
                <Link to="/add-recipe" className="bg-[#2E5A43] text-white px-4 py-2 rounded-md hover:bg-[#234735]">
                  Add Recipe
                </Link>
                <button
                  onClick={handleSignOut}
                  className="flex items-center space-x-1 text-gray-700 hover:text-[#2E5A43]"
                >
                  <LogOut className="h-5 w-5" />
                  <span>Sign Out</span>
                </button>
              </div>
            ) : (
              <Link to="/auth" className="flex items-center space-x-1 text-gray-700 hover:text-[#2E5A43]">
                <UserCircle className="h-5 w-5" />
                <span>Sign In</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}