import React from 'react';
import { Link } from 'react-router-dom';
import { ChefHat } from 'lucide-react';

export default function Home() {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-cover bg-center z-0" 
           style={{ 
             backgroundImage: 'url("https://images.unsplash.com/photo-1556910103-1c02745aae4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80")',
             filter: 'brightness(0.5)'
           }}>
      </div>
      
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-serif text-white mb-6">
            Welcome to Stan & Pan
          </h1>
          <p className="text-xl text-white mb-8 max-w-2xl mx-auto">
            Discover and share your favorite recipes from around the world. From family traditions to modern classics.
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/recipes"
              className="bg-[#2E5A43] text-white px-8 py-3 rounded-md hover:bg-[#234735] flex items-center space-x-2"
            >
              <ChefHat className="h-5 w-5" />
              <span>Explore Recipes</span>
            </Link>
          </div>
        </div>
      </div>
      
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <img
                src="https://images.unsplash.com/photo-1551218808-94e220e084d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80"
                alt="Family Recipes"
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
              <h3 className="text-xl font-serif mb-2">Family Traditions</h3>
              <p className="text-gray-600">Cherished recipes passed down through generations</p>
            </div>
            <div className="text-center">
              <img
                src="https://images.unsplash.com/photo-1547592180-85f173990554?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80"
                alt="Global Cuisine"
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
              <h3 className="text-xl font-serif mb-2">Global Flavors</h3>
              <p className="text-gray-600">Explore cuisines from around the world</p>
            </div>
            <div className="text-center">
              <img
                src="https://images.unsplash.com/photo-1466637574441-749b8f19452f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80"
                alt="Modern Recipes"
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
              <h3 className="text-xl font-serif mb-2">Modern Classics</h3>
              <p className="text-gray-600">Contemporary takes on beloved dishes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}